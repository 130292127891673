import React from "react";
import Header from "../LandingLayout/Header";
import Footer from "../LandingLayout/Footer";
import { Box } from "@mui/material";

const LandingLayout = ({ children }) => (
  <Box>
    <Header />
    <Box component="main">{children}</Box>
    <Footer />
  </Box>
);

export default LandingLayout;
