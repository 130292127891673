import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, useMediaQuery, Box, Badge, Menu, MenuItem, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowLeftIcon from "../../assets/images/icons/ArrowLeft.svg";
import ArrowRightIcon from "../../assets/images/icons/ArrowRight.svg";
import sidebarLogoIcon from "../../assets/images/sidebarLogoIcon.png"; // Import the smaller logo
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const drawerWidth = 280;
const closedDrawerWidth = 85;

function Header({ handleDrawerToggle, open }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  
  // State for the notification dropdown
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(5); // Example notification count

  // Handle notification dropdown
  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  // Handle profile dropdown
  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  return (
    <AppBar
      position="absolute"
      className="topbar"
      sx={{
        width: `calc(100% - ${!isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0}px)`,
        marginLeft: `${!isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0}px`,
        transition: "0.3s",
        padding: {
          xs: '0px 15px', 
          sm: '0px 20px', 
          md: '0px 30px', 
        },
      }}
    >
      <Toolbar>
        {isSmallScreen && (
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems:'center', justifyContent: 'flex-start' }}>
            <Box>
              <img
                src={sidebarLogoIcon}
                alt="Sidebar Logo"
                style={{ width: 'auto', height: '62.5px' }}
              />
            </Box>
            <Box sx={{pl:1}}>
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        )}

        {!isSmallScreen && (
          <IconButton onClick={handleDrawerToggle}>
            <img
              src={open ? ArrowLeftIcon : ArrowRightIcon}
              alt="Toggle Arrow"
            />
          </IconButton>
        )}

        <Box component="div" className="topbarRight">
          <Box component="div" className="topbarRight-Icon">
            <Link href="#" color="#404040">
              <SettingsOutlinedIcon />
            </Link>
          </Box>

          {/* Notification Icon with Badge */}
          <Box component="div" className="topbarRight">
            <IconButton onClick={handleNotificationClick} color="inherit">
              <Badge badgeContent={notificationsCount} color="primary">
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={notificationAnchorEl}
              open={Boolean(notificationAnchorEl)}
              onClose={handleNotificationClose}
            >
              <MenuItem onClick={handleNotificationClose}>Notification 1</MenuItem>
              <MenuItem onClick={handleNotificationClose}>Notification 2</MenuItem>
              <MenuItem onClick={handleNotificationClose}>Notification 3</MenuItem>
            </Menu>
          </Box>

          {/* Profile Icon and Dropdown */}
          <Box component="div" className="topbarRight">
            <IconButton onClick={handleProfileClick} color="inherit">
              <PersonOutlineOutlinedIcon />
            </IconButton>
            <Menu
              anchorEl={profileAnchorEl}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileClose}
            >
              <MenuItem onClick={handleProfileClose}>Profile</MenuItem>
              <MenuItem onClick={handleProfileClose}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
