export default function themeOverrides(theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor:theme.colors.white,
          boxShadow: 'none',
          padding: '0px 0px',
          '.MuiToolbar-root': {
            justifyContent: 'space-between',
            padding:"0px",
            minHeight:'auto',
            '.MuiButtonBase-root': {
              // minWidth:"155px"
            },
          }
        }
      }
    },    
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontSize: '16px',
          textTransform: 'uppercase',
          border: '1px solid transparent',
          fontWeight: 'bold',
          padding:'10px 30px',
          borderRadius:'7px',
          '&:hover, &:focus':{
            boxShadow:'none',
          },
          '&.MuiButton-containedPrimary': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
            '&:hover, &:focus': {
              backgroundColor: theme.colors.white,
              borderColor:theme.colors.primary,
              color:theme.colors.primary
            }
          },
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          color: "#404040",
          borderRadius: "0",
          '& .MuiInputBase-input': {
            padding: "16px 16px",
            backgroundColor: "#f8f8f8",
            outline: "none",
            borderRadius: "8px",
            height: "auto",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: "none",
            borderRadius: "0",
          },
          '& .MuiInputBase-input:-webkit-autofill': {
            backgroundColor: "#f8f8f8!important",
            color: "#404040",
            borderRadius: "8px",
            transition: "background-color 5000s ease-in-out 0s",
          },
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiListItem-root':{
            padding:"5px 16px",
            '& .MuiListItemButton-root':{
            padding:"10px 16px",
            borderRadius:"4px",
            '&:hover, &.active':{
              backgroundColor:theme.colors.primary,
              color:theme.colors.white,
              'img':{
                filter: 'invert(100%) sepia(0%) saturate(6634%) hue-rotate(73deg) brightness(125%) contrast(100%)',
              }
            },
            '& .MuiListItemIcon-root':{
              minWidth:"40px"
            },
            '& .MuiListItemText-root':{
              margin:"0px",
              '& .MuiTypography-root':{
                fontSize:"16px",
                lineHeight:"22px",
              }
            }
          }
          },
        },
      }
    }, 
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft:"0px",
          marginRight:"0px"
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius:"8px",
          boxShadow:"none",
          backgroundColor:"#f8f8f8",
        },
      }
    },    
    MuiCardContent: {
      styleOverrides: {
        root: {
         padding:"20px",
         '&:last-child':{
          paddingBottom:"16px"
         }
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '&.planTab':{
            '.MuiTabs-flexContainer': {
              gap:"15px",
            '.MuiButtonBase-root': {
              borderRadius:"30px",
              padding:"7px  21px",
              textTransform:"capitalize",
              fontSize:"16px",
              border:"1px solid #404040",
              minHeight:"40px",
              '&.Mui-selected': {
                backgroundColor: theme.colors.primary,
                color:theme.colors.white,
                borderColor:theme.colors.primary,
              },
            },
          },
          '.MuiTabs-indicator': {
            display: 'none'
          }
          }
        },
      },
    },   
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.TableContainer':{
            boxShadow:"none",
            padding:"20px",
            '&:last-child':{
              paddingBottom:"16px"
            }
          }
        },
      }
    }, 
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root':{
            '& .MuiTableRow-root':{
              '& .MuiTableCell-root':{
                fontWeight:"700",
              
                borderBottom:"0.5px solid #404040",
              }
            }
          },
          '& .MuiTableBody-root':{
            '& .MuiTableRow-root':{
              '& .MuiTableCell-root':{
                padding:'12px',
                border:"none"
              }
            }
          },
          '& .MuiTableCell-root':{
            padding:'12px',
            borderBottom:"1px solid #404040",
          }
        },
      }
    }, 
  };
}
