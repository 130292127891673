import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLandingBySection } from "../../store/thunk/landingThunk";
import { setBannerData } from "../../store/slice/landingSlice";
import { PageContent } from "../pageContent";
import { Heading } from "../heading";

const Slider = () => {
  const { t } = useTranslation();
  const language = i18next?.language;
  const dispatch = useDispatch();
  const { bannerData } = useSelector((state) => state.landing);
  useEffect(() => {
    dispatch(
      getLandingBySection({ section: "Banner", landingSlice: setBannerData }),
    );
  }, [dispatch]);

  return (
    <Box
      sx={{
        backgroundImage: "url(/static/images/home_slider.png)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <PageContent>
        <Grid
          container
          sx={{ height: "70vh", direction: language === "ar" && "rtl" }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <div>
                <Heading className="text-white">
                  {language === "ar"
                    ? bannerData[2]?.text_to_display_ar
                    : bannerData[2]?.text_to_display}
                  <Box component="span" sx={{ display: "block" }}>
                    {language === "ar"
                      ? bannerData[1]?.text_to_display_ar
                      : bannerData[1]?.text_to_display}
                  </Box>
                </Heading>
                <Typography
                  className="text-white"
                  sx={{ fontSize: "22px", mb: 4 }}
                >
                  {language === "ar"
                    ? bannerData[0]?.text_to_display_ar
                    : bannerData[0]?.text_to_display}
                  {/* {t(`slider.description`)} */}
                </Typography>
                <div>
                  <Button
                    sx={{
                      border: "1px solid #fff",
                      color: "#fff",
                    }}
                  >
                    {t(`slider.create`)}
                  </Button>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>
        </Grid>
      </PageContent>
    </Box>
  );
};

export default Slider;
