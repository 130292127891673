import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import statisticsData from "../../i18Next/locales/en.json";
import { ReactComponent as TraineeIcon } from "../../assets/images/icons/statistics1.svg";
import { ReactComponent as LogoIcon } from "../../assets/images/icons/statistics2.svg";
import { useTranslation } from "react-i18next";
import { PageContent } from "../pageContent";
import { Heading } from "../heading";

const Statistics = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // For tablets and smaller screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(to right, #4DADAD, #3564A6)",
      }}
    >
      <Box
        sx={{
          backgroundImage: "url(/static/images/statics.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <PageContent>
          <Heading textAlign="center" className="text-white">
            {t(`statistics.heading`)}
          </Heading>
          <Slider {...settings} className="statistics-slider">
            {statisticsData?.statistics?.slider?.map((slide, i) => (
              <Box
                component="div"
                className="text-center"
                key={i}
                sx={{
                  "& svg path": {
                    stroke: "#fff !important",
                  },
                }}
              >
                {slide?.heading === "Trainee" ? (
                  <TraineeIcon
                    sx={{ maxHeight: "155px", margin: "0px auto" }}
                  />
                ) : (
                  <LogoIcon sx={{ maxHeight: "155px", margin: "0px auto" }} />
                )}
                <Typography
                  variant="h1"
                  className="text-white"
                  fontWeight="700"
                  sx={{ mt: 3 }}
                >
                  {t(`statistics.slider.${i}.number`)}
                </Typography>
                <Typography
                  variant="h3"
                  className="text-white"
                  fontWeight="500"
                  sx={{ mb: 2, mt: 0.5 }}
                >
                  {t(`statistics.slider.${i}.heading`)}
                </Typography>
                <Typography variant="body1" className="text-white">
                  {t(`statistics.slider.${i}.description`)}
                </Typography>
              </Box>
            ))}
          </Slider>
        </PageContent>
      </Box>
    </Box>
  );
};

export default Statistics;
