import React from "react";
import Slider from "../../components/Home/Slider";
import Programs from "../../components/Home/Programs";
import Statistics from "../../components/Home/Statistics";

function Home() {
  return (
    <>
      <Slider />
      {/* <About /> */}
      <Programs />
      {/* <Chronicles /> */}
      {/* <LMSTimeline /> */}
      {/* <Vision /> */}
      {/* <Goals /> */}
      <Statistics />
      {/* <Whoweare /> */}
    </>
  );
}

export default Home;
