import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Tabs,
  Tab,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import planThumb from "../../assets/images/studyplan/plan-thumb.png";

// TabPanel Component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card sx={{ backgroundColor: "#fff", borderRadius: "4px", mt: 2 }}>
          <CardContent>{children}</CardContent>
        </Card>
      )}
    </div>
  );
}

const courses = [
  { name: 'Fundamentals of Mathematics', details: 'Number 202', refNo: '#230948', credit: '5h', status: 'Open', instructor: 'Dr. Ethan Caldwell' },
  { name: 'Introduction to Programming', details: 'Number 404', refNo: '#726357', credit: '3h', status: 'Open', instructor: 'Professor Serena Yates' },
  { name: 'Biology 101', details: 'Number 560', refNo: '#862358', credit: '4h', status: 'Closed', instructor: 'Mr. Oliver Harrington' },
  { name: 'Principles of Microeconomics', details: 'Number 445', refNo: '#632298', credit: '3h', status: 'Dropped', instructor: 'Ms. Fiona Delgado' },
  { name: 'Introduction to Philosophy', details: 'Number 099', refNo: '#621903', credit: '2h', status: 'Open', instructor: 'Dr. Julian Pierce' }
];

function StudyPlan() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Typography variant="h4" fontWeight="700" sx={{ mb: 2 }}>
        Your Study Plan
      </Typography>
      <Box component="section" className="studyplan-area">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Box component="div">
              <Card>
                <CardContent>
                  <Typography fontWeight="700">#293874634</Typography>
                  <Typography sx={{ mb: 2 }}>College Name</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <img src={planThumb} className="img-fluid" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box component="div">
                        <Box component="div" className="plan-info-row">
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              Admission Semester
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              Fall Semester
                            </Typography>
                          </Box>
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              Fee Factor
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              10 0000
                            </Typography>
                          </Box>
                        </Box>
                        <Box component="div" className="plan-info-row">
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              General Status
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              Active
                            </Typography>
                          </Box>
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              Mobile Number
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              00 000 0000
                            </Typography>
                          </Box>
                        </Box>
                        <Box component="div" className="plan-info-row">
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              Date of last status
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              19.10.2014
                            </Typography>
                          </Box>
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              Training Advisor
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              Leo Joel
                            </Typography>
                          </Box>
                        </Box>
                        <Box component="div" className="plan-info-row">
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              Semester Status
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              Active
                            </Typography>
                          </Box>
                          <Box component="div" className="single-info-row">
                            <Typography variant="bodySmall" component="p">
                              Nationality
                            </Typography>
                            <Typography
                              variant="bodySmall"
                              component="p"
                              fontWeight="700"
                            >
                              Saudi
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box component="div">
              <Card>
                <CardContent>
                  <Typography fontWeight="700" sx={{ mb: 2 }}>
                    Study Plan Credit hours and GPA
                  </Typography>
                  <Tabs
                    className="planTab"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Study Plan" />
                    <Tab label="Credit Hour" />
                    <Tab label="GPA" />
                  </Tabs>
                  <TabPanel value={value} index={0} className="">
                    <Box component="div" className="plan-creditinfo">
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Academic degree
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Bachelor’s, Master’s
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Study track
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Science, Arts
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Stage/level
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Undergraduate, Graduate
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Program
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Computer Science
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            College
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            College of Engineering
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Major specialization
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Business Administration
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Department
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Department of Mathematics
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Specialization subject
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Marketing Strategies
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Minor specialization
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Graphic Design
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Specialization topic
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Renewable Energy
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Type of admission
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Regular, Conditional
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Admission semester
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            Fall 2024
                          </Typography>
                        </Box>
                      </Box>
                      <Box component="div" className="plan-info-row">
                        <Box component="div" className="single-info-row">
                          <Typography variant="bodySmall" component="p">
                            Guidance semester
                          </Typography>
                        </Box>
                        <Box component="div" className="single-info-row">
                          <Typography
                            variant="bodySmall"
                            component="p"
                            fontWeight="700"
                          >
                            First Year Orientation
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1} className="">
                    <Box component="div" className="plan-creditinfo">
                      <Typography fontWeight="700">Credit Hour</Typography>
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={2} className="">
                    <Box component="div" className="plan-creditinfo">
                      <Typography fontWeight="700">GPA</Typography>
                    </Box>
                  </TabPanel>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box component="div">
              <Card>
                <CardContent>
                  <Typography fontWeight="700" sx={{ mb: 2 }}>
                    Registered Courses
                  </Typography>

                  <TableContainer component={Paper} className="TableContainer">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Course Name</TableCell>
                          <TableCell>Details</TableCell>
                          <TableCell>Reference No.</TableCell>
                          <TableCell>Credit Hours</TableCell>
                          <TableCell>Registration Status</TableCell>
                          <TableCell>Instructor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {courses.map((course, index) => (
                          <TableRow key={index}>
                            <TableCell>{course.name}</TableCell>
                            <TableCell>{course.details}</TableCell>
                            <TableCell>{course.refNo}</TableCell>
                            <TableCell>{course.credit}</TableCell>
                            <TableCell>{course.status}</TableCell>
                            <TableCell>{course.instructor}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default StudyPlan;
