import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Paper, Typography } from "@mui/material";
import i18next from "i18next";

export const PoliticalMap = () => {
  const language = i18next?.language;
  const [selectedState, setSelectedState] = useState(null);

  return (
    <div data-tip="" style={{ position: "relative" }}>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          center: [46.672048332673626, 24.62731217741549],
          scale: 1000,
        }}
        height={350}
      >
        <Geographies geography="/static/allstate.json">
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onClick={() => setSelectedState(geo)}
                style={{
                  default: {
                    fill:
                      selectedState?.properties?.name === geo?.properties?.name
                        ? "#3a92a6"
                        : "#a7d1e2",
                    animation:
                      selectedState?.properties?.name === geo?.properties?.name
                        ? "scale 5s ease-in infinite"
                        : "",
                    outline: "none",
                  },
                  hover: {
                    fill: "#3a92a6",
                    outline: "none",
                    cursor: "pointer",
                  },
                  pressed: {
                    fill: "#3a92a6",
                    outline: "none",
                  },
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
      {/* Info Window (Popup) */}
      {selectedState && (
        <Paper elevation={3}>
          <div
            style={{
              position: "absolute",
              height: "100px",
              backgroundColor: "#ffffff",
              color: "#50BBCA",
              padding: "10px",
              borderRadius: "5px",
              zIndex: 1000,
              width: "280px",
              cursor: "pointer",
              transform: `translate(${selectedState?.properties?.coords[1] + 300}px,${selectedState?.properties?.coords[0] + 10}px)`,
              transition: "all .4s ease-in-out",
              top: "0px",
              left: "0px",
              textAlign: "center",
              boxShadow: "0 0 35px #a7d1e2",
            }}
            onClick={() => setSelectedState(null)} // Close info window on click
          >
            <Typography fontWeight="700" sx={{ color: "#125883" }}>
              {language === "ar"
                ? "إدارات التدريب التقني والمهني"
                : "Technical and Vocational Training Corporation"}
            </Typography>
            <Typography fontWeight="700">
              {language === "ar"
                ? selectedState?.properties?.name
                : selectedState?.properties?.name_to_en}
            </Typography>
          </div>
        </Paper>
      )}
    </div>
  );
};
