import { combineReducers, configureStore } from "@reduxjs/toolkit";
import notificationSlice from "./slice/notificationSlice";
import landingSlice from "./slice/landingSlice";

const rootReducer = combineReducers({
  notification: notificationSlice,
  landing: landingSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
