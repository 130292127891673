import React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  useMediaQuery,
} from "@mui/material";
import sidebarLogo from "../../assets/images/logo.svg";
import sidebarLogoIcon from "../../assets/images/sidebarLogoIcon.png";
import { useNavigate, useLocation } from "react-router-dom";

import dashboardIcon from "../../assets/images/icons/dashboardIcon.svg";
import studyPlanIcon from "../../assets/images/icons/studyPlanIcon.svg";
import qualificationsIcon from "../../assets/images/icons/qualificationsIcon.svg";
import extraLinksIcon from "../../assets/images/icons/extraLinksIcon.svg";
import certificateIcon from "../../assets/images/icons/certificateIcon.svg";
import transcriptIcon from "../../assets/images/icons/transcriptIcon.svg";
import graduationIcon from "../../assets/images/icons/graduationIcon.svg";
import scheduleIcon from "../../assets/images/icons/scheduleIcon.svg";
import registrationIcon from "../../assets/images/icons/registrationIcon.svg";
import gradesIcon from "../../assets/images/icons/gradesIcon.svg";

const drawerWidth = 280;
const closedDrawerWidth = 85;

function Sidebar({ open, handleDrawerToggle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const menuItems = [
    { label: "Dashboard", icon: dashboardIcon, link: "/dashboard" },
    { label: "Study Plan", icon: studyPlanIcon, link: "/studyplan" },
    { label: "Prior Qualifications", icon: qualificationsIcon, link: "/" },
    { label: "Extra Links", icon: extraLinksIcon, link: "/" },
    { label: "Certificate Evaluation", icon: certificateIcon, link: "/" },
    { label: "Grades Transcript", icon: transcriptIcon, link: "/" },
    { label: "Graduation Request", icon: graduationIcon, link: "/" },
    { label: "Weekly Schedule", icon: scheduleIcon, link: "/" },
    { label: "Registration", icon: registrationIcon, link: "/" },
    { label: "Grades View", icon: gradesIcon, link: "/" },
  ];

  const isActive = (link) => location.pathname === link;

  return (
    <Drawer
      variant={isSmallScreen ? "temporary" : "permanent"}
      anchor="left"
      open={open}
      onClose={handleDrawerToggle}
      sx={{
        width: isSmallScreen ? drawerWidth : (open ? drawerWidth : closedDrawerWidth),
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isSmallScreen ? drawerWidth : (open ? drawerWidth : closedDrawerWidth),
          boxSizing: "border-box",
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box>
        {/* Sidebar Logo */}
        <Box mb={1.8} sx={{ textAlign: "center", backgroundColor:"#F8F8F8", boxShadow:"0px 0px 4px 0px #00000029", }}>
          <img
            src={open ? sidebarLogo : sidebarLogoIcon}
            className={open ? "largeLogo" : "smallLogo"}
            style={{ maxWidth: open ? "calc(100% - 40px)" : "62.5px" }}
            alt="Logo"
          />
        </Box>

        {/* Sidebar Menu Items */}
        <List disablePadding>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                className={isActive(item.link) ? "active" : ""}
                onClick={() => navigate(item.link)}
              >
                <ListItemIcon>
                  <img
                    src={item.icon}
                    alt={item.label}
                    style={{ width: open ? 22 : 22, height: open ? 22 : 22 }}
                  />
                </ListItemIcon>
                {open && <ListItemText primary={item.label} />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
