/**
 * Typography used in theme with responsive design (using max-width media queries)
 * @param {JsonObject} theme theme customization object
 */
export default function themeTypography(theme = {}) {
  return {
    fontFamily: '"Cairo"',
    h1: {
      fontSize: '40px',
      '@media (max-width: 600px)': {  
        fontSize: '32px', 
      },
    },
    h2: {
      fontSize: '35px',
      '@media (max-width: 600px)': {  
        fontSize: '28px', 
      },
    },
    h3: {
      fontSize: '30px',
      '@media (max-width: 600px)': {  
        fontSize: '24px', 
      },
    },
    h4: {
      fontSize: '24px',
      '@media (max-width: 600px)': {  
        fontSize: '20px', 
      },
    },
    h5: {
      fontSize: '20px',
      '@media (max-width: 600px)': {  
        fontSize: '18px', 
      },
    },
    bodySmall: {
      fontSize: '14px',
      lineHeight: '20px',
      '@media (max-width: 600px)': {  
        fontSize: '12px',  
        lineHeight: '18px',  
      },
    },
    body: {
      fontSize: '16px',
      lineHeight: '26px',
      '@media (max-width: 600px)': {  
        fontSize: '14px',  
        lineHeight: '22px',  
      },
    },
    body1: {
      fontSize: '18px',
      lineHeight: '28px',
      '@media (max-width: 600px)': {  
        fontSize: '16px',  
        lineHeight: '24px',  
      },
    },
    countTitle: {
      fontSize: '65px',
      color: '#50BBCA',
    },
  };
}
