import axios from "axios";

export const authApiWithToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
console.log("process.env", process.env);

authApiWithToken.interceptors.request.use((request) => {
  try {
    request.headers["Authorization"] =
      `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM4MDY0OTE3LCJpYXQiOjE3Mzc0NjAxMTcsImp0aSI6IjJhODI1MTgzMGU2YjQxM2JiZTc5NzhmMTRlMWEzYjYxIiwidXNlcl9pZCI6MX0.j3wAfjp43LCw_e5VYXyspn2rNeP7P0OXoYDsXSK3DsI`;
    return request;
  } catch (refreshError) {
    console.error("Refresh token failed:", refreshError);
    return Promise.reject(refreshError);
  }
});

export default authApiWithToken;
