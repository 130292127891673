import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Typography } from "@mui/material";

// Layouts
import LandingLayout from "../LandingLayout";
import MinimalLayout from "../MinimalLayout";
import MainLayout from "../MainLayout";

// Pages
import Home from "../../view/pages/Home";
import Login from "../../view/pages/Login";
import Dashboard from "../../view/pages/Dashboard";
import StudyPlan from "../../view/pages/StudyPlan";
import About from "../../components/Home/About";
import Vision from "../../components/Home/Vision";
import Goals from "../../components/Home/Goals";
import Whoweare from "../../components/Home/Whoweare";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Landing Page Route */}
        <Route
          path="/"
          element={
            <LandingLayout>
              <Home />
            </LandingLayout>
          }
        />
        <Route
          path="about-us"
          element={
            <LandingLayout>
              <About />
            </LandingLayout>
          }
        />
        <Route
          path="vision"
          element={
            <LandingLayout>
              <Vision />
            </LandingLayout>
          }
        />
        <Route
          path="strategic-vision"
          element={
            <LandingLayout>
              <Goals />
            </LandingLayout>
          }
        />
        <Route
          path="location"
          element={
            <LandingLayout>
              <Whoweare />
            </LandingLayout>
          }
        />

        <Route
          path="*"
          element={
            <LandingLayout>
              <Typography sx={{ minHeight: "350px", p: 16 }}></Typography>
            </LandingLayout>
          }
        />

        {/* Login Page Route */}
        <Route
          path="/login"
          element={
            <MinimalLayout>
              <Login />
            </MinimalLayout>
          }
        />

        {/* Dashboard Page Route */}
        <Route
          path="/dashboard"
          element={
            <MainLayout>
              <Dashboard />
            </MainLayout>
          }
        />

        {/* StudyPlan Page Route */}
        <Route
          path="/studyplan"
          element={
            <MainLayout>
              <StudyPlan />
            </MainLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
